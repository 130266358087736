import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {
  BrassSort,
  IBrassMp3Album,
  IMinBrassData,
  IMinBrassDetail,
  IMinBrassSeriesData,
} from '../../definitions/brass';
import {IAction, IStore} from '../store';

interface IFetchBrassGridDataOptions {
  includeCccData: boolean;
  includeHofData: boolean;
}

export const FETCH_BRASS_GRID_DATA_STARTED = 'brass/fetchGridStarted';
function fetchBrassGridDataStarted(): IAction {
  return {
    type: FETCH_BRASS_GRID_DATA_STARTED,
  };
}
export const FETCH_BRASS_GRID_DATA_RECEIVE = 'brass/fetchGridReceive';
function fetchBrassGridDataReceive(
  data: IMinBrassData,
  options?: Partial<IFetchBrassGridDataOptions>,
): IAction {
  return {
    data,
    includeCccData: !!options?.includeCccData,
    includeHofData: !!options?.includeHofData,
    type: FETCH_BRASS_GRID_DATA_RECEIVE,
  };
}
export const FETCH_BRASS_GRID_DATA_ERROR = 'brass/fetchGridError';
function fetchBrassGridDataError(): IAction {
  return {
    type: FETCH_BRASS_GRID_DATA_ERROR,
  };
}
export function fetchBrassGridData(
  options?: Partial<IFetchBrassGridDataOptions>,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchBrassGridDataStarted());

    return fetch(`/api/brass`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchBrassGridDataError());
          console.error(`Error fetching brass data: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchBrassGridDataReceive(data, options));
        });
      })
      .catch((err) => {
        dispatch(fetchBrassGridDataError());
        dispatch(fetchBrassPublicationDataError());
        console.error(`Error parsing brass data: ${err.stack}`);
      });
  };
}

export const FETCH_BRASS_SERIES_STARTED = 'brass/fetchSeriesStarted';
function fetchBrassSeriesStarted(): IAction {
  return {
    type: FETCH_BRASS_SERIES_STARTED,
  };
}
export const FETCH_BRASS_SERIES_RECEIVE = 'brass/fetchSeriesReceive';
function fetchBrassSeriesReceive(data: IMinBrassSeriesData): IAction {
  return {
    data,
    type: FETCH_BRASS_SERIES_RECEIVE,
  };
}
export const FETCH_BRASS_SERIES_ERROR = 'brass/fetchSeriesError';
function fetchBrassSeriesError(): IAction {
  return {
    type: FETCH_BRASS_SERIES_ERROR,
  };
}
export function fetchBrassSeries(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchBrassSeriesStarted());

    return fetch(`/api/brass/series`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchBrassSeriesError());
          console.error(`Error fetching brass series data: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchBrassSeriesReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchBrassSeriesError());
        console.error(`Error parsing brass series data: ${err.stack}`);
      });
  };
}

export const FETCH_BRASS_DETAIL_STARTED = 'brass/fetchDetailStarted';
function fetchBrassDetailStarted(): IAction {
  return {
    type: FETCH_BRASS_DETAIL_STARTED,
  };
}
export const FETCH_BRASS_DETAIL_RECEIVE = 'brass/fetchDetailReceive';
function fetchBrassDetailReceive(data: IMinBrassDetail): IAction {
  return {
    data,
    type: FETCH_BRASS_DETAIL_RECEIVE,
  };
}
export const FETCH_BRASS_DETAIL_ERROR = 'brass/fetchBrassDetailError';
function fetchBrassDetailError(): IAction {
  return {
    type: FETCH_BRASS_DETAIL_ERROR,
  };
}
export function fetchBrassDetail(
  publicationId: string,
  onDone: () => void,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchBrassDetailStarted());

    return fetch(`/api/brass/detail/${publicationId}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchBrassDetailError());
          console.error(`Error fetching brass detail: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchBrassDetailReceive(data));
          onDone();
        });
      })
      .catch((err) => {
        dispatch(fetchBrassDetailError());
        console.error(`Error parsing brass detail: ${err.stack}`);
      });
  };
}

export const FETCH_BRASS_MP3_ALBUM_STARTED = 'brass/fetchMp3AlbumStarted';
function fetchBrassMp3AlbumStarted(): IAction {
  return {
    type: FETCH_BRASS_MP3_ALBUM_STARTED,
  };
}
export const FETCH_BRASS_MP3_ALBUM_RECEIVE = 'brass/fetchMp3AlbumReceive';
function fetchBrassMp3AlbumReceive(data: IBrassMp3Album): IAction {
  return {
    data,
    type: FETCH_BRASS_MP3_ALBUM_RECEIVE,
  };
}
export const FETCH_BRASS_MP3_ALBUM_ERROR = 'brass/fetchBrassMp3AlbumError';
function fetchBrassMp3AlbumError(): IAction {
  return {
    type: FETCH_BRASS_MP3_ALBUM_ERROR,
  };
}
export function fetchBrassMp3Album(
  mp3AlbumId: number,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchBrassMp3AlbumStarted());

    return fetch(`/api/brass/mp3-album/${mp3AlbumId}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchBrassMp3AlbumError());
          console.error(`Error fetching brass mp3 album: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchBrassMp3AlbumReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchBrassMp3AlbumError());
        console.error(`Error parsing brass mp3 album: ${err.stack}`);
      });
  };
}

export const FETCH_BRASS_PUBLICATION_DATA_STARTED =
  'brass/fetchPublicationsStarted';
function fetchBrassPublicationDataStarted(): IAction {
  return {
    type: FETCH_BRASS_PUBLICATION_DATA_STARTED,
  };
}
export const FETCH_BRASS_PUBLICATION_DATA_RECEIVE =
  'brass/fetchPublicationsReceive';
function fetchBrassPublicationDataReceive(): IAction {
  return {
    type: FETCH_BRASS_PUBLICATION_DATA_RECEIVE,
  };
}
export const FETCH_BRASS_PUBLICATION_DATA_ERROR =
  'brass/fetchPublicationsError';
function fetchBrassPublicationDataError(): IAction {
  return {
    type: FETCH_BRASS_PUBLICATION_DATA_ERROR,
  };
}
export function fetchBrassPublicationData(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (
    dispatch: ThunkDispatch<IStore, null, IAction>,
    getState: () => IStore,
  ) => {
    dispatch(fetchBrassPublicationDataStarted());

    if (getState().brass.brassGridData.detail.length) {
      // Grid data is already fetched, don't bother re-fetching
      dispatch(fetchBrassPublicationDataReceive());
      return Promise.resolve();
    }

    return dispatch(fetchBrassGridData()).then(() => {
      dispatch(fetchBrassPublicationDataReceive());
    });
  };
}

export const RESET_BRASS_DETAIL = 'brass/detail/reset';
export function resetBrassDetail(): IAction {
  return {
    type: RESET_BRASS_DETAIL,
  };
}

export const RESET_BRASS_MP3_ALBUM = 'brass/mp3Album/reset';
export function resetBrassMp3Album(): IAction {
  return {
    type: RESET_BRASS_MP3_ALBUM,
  };
}

export const FILTER_BRASS = 'brass/filter';
export function filterBrass(key: string, value: boolean | string): IAction {
  return {
    key,
    type: FILTER_BRASS,
    value,
  };
}

export const FILTER_BRASS_PRIME = 'brass/filter/prime';
export function primeBrassFilter(
  key: string,
  value: boolean | string,
): IAction {
  return {
    key,
    type: FILTER_BRASS_PRIME,
    value,
  };
}

export const FILTER_BRASS_EXECUTE = 'brass/filter/execute';
export function executeBrassFilters(): IAction {
  return {
    type: FILTER_BRASS_EXECUTE,
  };
}

export const FILTER_BRASS_RESET = 'brass/filter/reset';
export function resetBrassFilters(): IAction {
  return {
    type: FILTER_BRASS_RESET,
  };
}

export const SORT_BRASS = 'brass/sort';
export function sortBrass(brassSort: BrassSort): IAction {
  return {
    brassSort,
    type: SORT_BRASS,
  };
}

export const SORT_BRASS_PRIME = 'brass/sort/prime';
export function primeBrassSort(brassSort: BrassSort): IAction {
  return {
    brassSort,
    type: SORT_BRASS_PRIME,
  };
}

export const SORT_BRASS_EXECUTE = 'brass/sort/execute';
export function executeBrassSort(): IAction {
  return {
    type: SORT_BRASS_EXECUTE,
  };
}

export const UPDATE_BRASS_DETAIL_INDEX = '/brass/updateDetailIndex';
export function updateBrassDetailIndex(index: number): IAction {
  return {
    index,
    type: UPDATE_BRASS_DETAIL_INDEX,
  };
}
