import debounce from 'lodash-es/debounce';
import React, {Suspense, lazy} from 'react';
import {connect} from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import {ThunkDispatch} from 'redux-thunk';
import Spinner from './components/spinner';
import {
  domResize,
  fetchBasket,
  fetchThemes,
  fetchUserDetail,
  setDebugMode,
  setInstallPrompt,
  setRecaptchaReady,
} from './redux/actions';
import {IAction, IStore} from './redux/store';
import getUrlArgs from './utils/getUrlArgs';
import {pendoLoginAnonymous} from './utils/pendo';

// Dynamic Imports
const About = lazy(() => import('./components/about'));
const AccountMain = lazy(() => import('./components/account/main'));
const AdminHome = lazy(() => import('./components/admin/home'));
const Basket = lazy(() => import('./components/basket'));
const Brass = lazy(() => import('./components/brass'));
const BrassDetail = lazy(() => import('./components/brassDetail'));
const BrassHallOfFame = lazy(() => import('./components/brassHallOfFame'));
const BrassMp3Album = lazy(() => import('./components/brassMp3Album'));
const CarolAccompaniments = lazy(
  () => import('./components/carolAccompaniments'),
);
const CelebratingChristmasAtHome = lazy(
  () => import('./components/celebratingChristmasAtHome'),
);
const ChristmasCollectionChallenge = lazy(
  () => import('./components/christmasCollectionChallenge'),
);
const Contact = lazy(() => import('./components/contact'));
const EasterHymnAccompaniments = lazy(
  () => import('./components/easterHymnAccompaniments'),
);
const EasterResources = lazy(() => import('./components/easterResources'));
const ErrorPage = lazy(() => import('./components/errorPage'));
const FAQ = lazy(() => import('./components/faq'));
const HallOfFame = lazy(() => import('./components/hallOfFame'));
const Home = lazy(() => import('./components/home'));
const NotFound = lazy(() => import('./components/notFound'));
const PrivacyPolicy = lazy(() => import('./components/privacyPolicy'));
const ResetPassword = lazy(() => import('./components/resetPassword'));
const TermsAndConditions = lazy(
  () => import('./components/termsAndConditions'),
);
const Vocal = lazy(() => import('./components/vocal'));
const VocalDetail = lazy(() => import('./components/vocalDetail'));
const VocalHallOfFame = lazy(() => import('./components/vocalHallOfFame'));
const VocalMp3Album = lazy(() => import('./components/vocalMp3Album'));

declare global {
  interface Window {
    grecaptcha: any;
    onRecaptchaLoad: () => void;
  }
}

interface IMainDispatchProps {
  domResize: () => void;
  fetchBasket: () => void;
  fetchThemes: () => void;
  fetchUserDetail: () => void;
  setDebugMode: (debugMode: boolean) => void;
  setInstallPrompt: (installPrompt: any) => void;
  setRecaptchaReady: () => void;
}

interface IMainProps extends RouteComponentProps<{}>, IMainDispatchProps {}

class Main extends React.PureComponent<IMainProps, {}> {
  public constructor(props: IMainProps) {
    super(props);
    this.setupEventListeners(props);
    this.setupApiFunctions();
  }

  public componentDidMount() {
    this.handlePendo();
    this.checkDebugMode();
    this.props.fetchUserDetail();
    this.props.fetchThemes();
    this.props.fetchBasket();
    this.addRecaptchaScript();
  }

  public componentDidUpdate(prevProps: IMainProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    return (
      <div className="main-content">
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            <Route exact path="/about">
              <About />
            </Route>

            <Route path="/account">
              <AccountMain />
            </Route>

            <Route path="/admin">
              <AdminHome />
            </Route>

            <Route exact path="/basket">
              <Basket />
            </Route>

            <Route exact path="/brass">
              <Brass />
            </Route>

            <Route exact path="/brass/mp3Album/:id">
              <BrassMp3Album />
            </Route>

            <Route exact path="/brass/:title/:id">
              <BrassDetail />
            </Route>

            <Route exact path="/celebrating-christmas-at-home">
              <CelebratingChristmasAtHome />
            </Route>

            <Route
              exact
              path="/celebrating-christmas-at-home/carol-accompaniments"
            >
              <CarolAccompaniments />
            </Route>

            <Route exact path="/christmas-collection-challenge">
              <ChristmasCollectionChallenge />
            </Route>

            <Route exact path="/contact">
              <Contact />
            </Route>

            <Route exact path="/easter-resources">
              <EasterResources />
            </Route>

            <Route exact path="/easter-resources/hymn-accompaniments">
              <EasterHymnAccompaniments />
            </Route>

            <Route exact path="/error/:code">
              <ErrorPage />
            </Route>

            <Route exact path="/hall-of-fame">
              <HallOfFame />
            </Route>

            <Route exact path="/hall-of-fame/brass/:year/:id">
              <BrassHallOfFame />
            </Route>

            <Route exact path="/hall-of-fame/vocal/:year/:id">
              <VocalHallOfFame />
            </Route>

            <Route exact path="/redeem">
              <Redirect
                to={{
                  pathname: '/account/subscriptions/redeem',
                  search: this.props.location.search,
                }}
              />
            </Route>

            <Route exact path="/reset-password">
              <ResetPassword />
            </Route>

            <Route exact path="/faq/:question?">
              <FAQ />
            </Route>

            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>

            <Route exact path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>

            <Route exact path="/vocal">
              <Vocal />
            </Route>

            <Route exact path="/vocal/mp3Album/:id">
              <VocalMp3Album />
            </Route>

            <Route exact path="/vocal/:title/:id">
              <VocalDetail />
            </Route>

            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </div>
    );
  }

  private handlePendo(): void {
    // Initially log in anonymously.
    // Authenticated users will be identified upon login.
    pendoLoginAnonymous();
  }

  private checkDebugMode(): void {
    if (getUrlArgs().debug !== undefined) {
      this.props.setDebugMode(true);
    }
  }

  private setupEventListeners(props: IMainProps): void {
    window.addEventListener(
      'resize',
      debounce(() => {
        this.props.domResize();
      }, 250),
    );

    window.addEventListener('beforeinstallprompt', (e: any) => {
      e.preventDefault();
      props.setInstallPrompt(e);
    });
  }

  private setupApiFunctions(): void {
    window.onRecaptchaLoad = () => {
      this.props.setRecaptchaReady();
    };
  }

  private addRecaptchaScript(): void {
    const tag = document.createElement('script');

    tag.src =
      'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit';
    tag.async = true;
    tag.defer = true;

    const firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag.parentNode) {
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IStore, null, IAction>,
): IMainDispatchProps => ({
  domResize: () => dispatch(domResize()),
  fetchBasket: () => dispatch(fetchBasket()),
  fetchThemes: () => dispatch(fetchThemes()),
  fetchUserDetail: () => dispatch(fetchUserDetail()),
  setDebugMode: (debugMode: boolean) => dispatch(setDebugMode(debugMode)),
  setInstallPrompt: (installPrompt: any) =>
    dispatch(setInstallPrompt(installPrompt)),
  setRecaptchaReady: () => dispatch(setRecaptchaReady()),
});

export default withRouter(
  connect<{}, IMainDispatchProps, RouteComponentProps<{}>>(
    undefined,
    mapDispatchToProps,
  )(Main),
);
